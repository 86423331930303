jQuery(document).ready(() => {
  jQuery(document).on('click', '#close-search, .openSearch > .after', () => {
    jQuery('body').removeClass('openSearch');
    return false;
  });

  jQuery(document).on('click', '#open-search', () => {
    jQuery('body').addClass('openSearch');
    jQuery('.search-field').focus();
    return false;
  });
});
