jQuery(document).ready(function($) {

  $(window).on("load", function() {

    // Obtenha a classe do elemento <body>
      const bodyClass = $('body').attr('class');

      if(!bodyClass.includes('single')) {
        return
      }

      // Extraia o ID do post da classe
      let postId = bodyClass.match(/postid-(\d+)/);
      if (postId && postId[1]) {
        postId = postId[1];

        $.ajax({
            url: usAjax.ajaxurl,
            type: 'POST',
            data: {
                action: 'web_set_post_views',
                postID: postId
            }
        });
    }

  });

});
