jQuery(window).on('load', () => {
  function wrapContent(index) {
    const newElement = `<div class="BoxVideoPlayer BoxVideoPlayer-${index}"></div>`;
    const cloneElement = jQuery(this).clone(true);
    jQuery(this).replaceWith(newElement);
    cloneElement.appendTo(`.BoxVideoPlayer-${index}`);
  }

  if (jQuery('#postCont iframe').length > 0) {
    jQuery('#postCont iframe').each(wrapContent);
  }

  const ALLOW_LIST_IFRAME = [
    'accelerometer',
    'autoplay',
    'clipboard-write',
    'encrypted-media',
    'gyroscope',
    'picture-in-picture',
    'web-share'
  ];

  jQuery('#postCont .BoxVideoPlayer--lazy-load button').on('click', function () {
    const box = this.parentElement;
    const iframeAttrs = JSON.parse(box.dataset.iframeAttributes);
    const allowList = iframeAttrs.allow?.split(';').map(item => item.trim()) ?? [];

    ALLOW_LIST_IFRAME.forEach(permission => {
      if (!allowList.includes(permission)) {
        allowList.push(permission);
      }
    });
    iframeAttrs.allow = allowList.join('; ');

    const iframe = document.createElement('iframe');

    Object.entries(iframeAttrs).map(([key, value]) => {
      if (key === 'src') {
        const src = new URL(value);
        src.searchParams.set('autoplay', '1')
        iframe.setAttribute(key, src.toString());
      } else {
        iframe.setAttribute(key, value);
      }
    });

    delete box.dataset.iframeAttributes;
    box.classList.add('is-loaded');
    box.replaceChildren(iframe);
  });

  //Checa se há um iframe do instagram, se sim adiciona uma classe ao elemento pai
  //Feito para corrigir a altura do embed do Instagram, pois estava sendo cortado.
  const iframeIG = document.querySelectorAll('iframe.instagram-media.instagram-media-rendered');
  if (iframeIG.length > 0) {
    iframeIG.forEach((element) => {
      element.parentNode.classList.add('instagram-embed');
    })
  }

});
