jQuery(document).ready(() => {
  if (jQuery(window).scrollTop() >= 20) {
    jQuery('body').addClass('rolling');
  } else {
    jQuery('body').removeClass('rolling');
  }
  jQuery(window).on('scroll', function () {
    if (jQuery(this).scrollTop() >= 20) {
      jQuery('body').addClass('rolling');
    } else {
      jQuery('body').removeClass('rolling');
    }
  });
});
