jQuery(document).ready(() => {
  jQuery('.slick-slider-postimg').each(function () {
    const $slidePostimg = jQuery(`#${jQuery(this).attr('id')} .featured-postimg`);
    $slidePostimg.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<a href="#" class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
      prevArrow: '<a href="#" class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
      fade: true,
      asNavFor: `#${jQuery(this).attr('id')} .list-postimg`,
    });

    const $slidePostimglist = jQuery(`#${jQuery(this).attr('id')} .list-postimg`);
    $slidePostimglist.slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      infinite: true,
      nextArrow: '<a href="#" class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-angle-right" aria-hidden="true"></i></a>',
      prevArrow: '<a href="#" class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fa fa-angle-left" aria-hidden="true"></i></a>',
      centerMode: false,
      focusOnSelect: true,
      adaptiveHeight: true,
      centerPadding: 0,
      asNavFor: `#${jQuery(this).attr('id')} .featured-postimg`,
    });
  });
});
