jQuery(document).ready(() => {
  function handleLoader() {
    const $url = jQuery(this).attr('href');
    if ($url) {
      jQuery('body').addClass('loading');
      jQuery('.Multispinner-container').addClass('active');
      jQuery.ajax({
        url: $url,
        headers: { 'is-pagination': true },
        dataType: 'html',
        success(data) {
          jQuery('#getCont').html(jQuery(data).find('#getCont').html());
          jQuery('.pag').html(jQuery(data).find('.pag').html());
          jQuery('body').removeClass('loading');
          jQuery('.Multispinner-container').removeClass('active');
        },
      });
      history.pushState(null, '', $url);
    }
    return false;
  }

  jQuery(document).on('click', '.pag a', handleLoader);
});
