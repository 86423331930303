jQuery(document).ready(() => {
  jQuery('.AuthorsSlider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    nextArrow: '<button class="SliderArrow slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-angle-right" aria-hidden="true"></i></button>',
    prevArrow: '<button class="SliderArrow slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fa fa-angle-left" aria-hidden="true"></i></button>',
    centerMode: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    centerPadding: 10,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: true,
        },
      },
    ],
  });
});
