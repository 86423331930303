import './modules/authorsSlider';
import './modules/categoryNewsSlider';
import './modules/content';
import './modules/header';
import './modules/mobileMenu';
import './modules/pagination';
import './modules/postSlider';
import './modules/search';
import './modules/webStoriesSlider';
import './modules/postviewcounter';

